import Vue from 'vue'
import VueRouter from 'vue-router'
import {getHomeRouteForLoggedInUser, isUserLoggedIn} from "@/auth/utils"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {path: '/', redirect: {name: 'home'}},
        {
            path: '/dashboard',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-profiles',
            name: 'admin-profiles',
            component: () => import('@/views/profiles/ProfilesView.vue'),
            meta: {
                pageTitle: 'Perfiles',
                breadcrumb: [
                    {
                        text: 'Perfiles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-orders',
            name: 'admin-orders',
            component: () => import('@/views/orders/OrdersView.vue'),
            meta: {
                pageTitle: 'Ordenes',
                breadcrumb: [
                    {
                        text: 'Ordenes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-orders/:id',
            name: 'admin-order-details',
            component: () => import('@/views/order-details/OrderDetailsView.vue'),
            meta: {
                pageTitle: 'Detalle de orden',
                breadcrumb: [
                    {
                        text: 'Ordenes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-products',
            name: 'admin-products',
            component: () => import('@/views/products/ProductsView.vue'),
            meta: {
                pageTitle: 'Productos',
                breadcrumb: [
                    {
                        text: 'Productos',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-categories',
            name: 'admin-categories',
            component: () => import('@/views/categories/CategoriesView.vue'),
            meta: {
                pageTitle: 'Categorías',
                breadcrumb: [
                    {
                        text: 'Categorías',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/admin-users',
            name: 'admin-users',
            component: () => import('@/views/admin-users/AdminUsersView.vue'),
            meta: {
                pageTitle: 'Usuarios administrativos',
                breadcrumb: [
                    {
                        text: 'Usuarios administrativos',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/admin-news',
            name: 'admin-news',
            component: () => import('@/views/news/NewsView'),
            meta: {
                pageTitle: 'Noticias',
                breadcrumb: [
                    {
                        text: 'Noticias',
                        active: true,
                    },
                ],
            },
        },

        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (isLoggedIn === false && to.name !== 'login') return next({name: 'login'})

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        //const userData = getUserData()
        next(getHomeRouteForLoggedInUser())
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
