import { $themeBreakpoints } from '@themeConfig'
import firebase from 'firebase/compat/app'

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        firebaseApp: null,
        counters: {
            profilesCount: 0,
        }
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state
            if (windowWidth >= $themeBreakpoints.xl) return 'xl'
            if (windowWidth >= $themeBreakpoints.lg) return 'lg'
            if (windowWidth >= $themeBreakpoints.md) return 'md'
            if (windowWidth >= $themeBreakpoints.sm) return 'sm'
            return 'xs'
        },
        counters: state => state.counters
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
        },
        INITIALIZE_FIREBASE(state, obj) {
            state.firebaseApp = firebase.initializeApp(obj)
            firebase.firestore()
              .collection('counters')
              .doc('0')
              .onSnapshot(doc => {
                  state.counters = doc.data()
              })
        },
    },
    actions: {},
}
