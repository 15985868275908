import firebase from "firebase/compat/app"

export default {
    namespaced: true,
    state: {
        user: null
    },
    getters: {
        getUser: state => {
            return state.user
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        }
    },
    actions: {
        setUser(context, user){
            context.commit('setUser', user)
        },
        getAdminUserByEmail(ctx, email) {
            return new Promise((resolve, reject) => {
                firebase
                    .firestore()
                    .collection('admin_users')
                    .where('username', '==', email)
                    .get()
                    .then(snapshot => {
                        if (snapshot.docs.length > 0) {
                            const user = snapshot.docs[0].data()
                            user.id = snapshot.docs[0].id

                            resolve(user)
                        } else {
                            reject('No se encontró el usuario')
                        }
                    })
            })
        }
    }
}
