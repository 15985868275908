import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

import firebase from "firebase/compat/app"
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/functions'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const firebaseConfig = {
    apiKey: 'AIzaSyDr_EiUuoui52Y_Hait622svpDzChDpjOM',
    authDomain: 'pruebas-a312e.firebaseapp.com',
    databaseURL: 'https://pruebas-a312e.firebaseio.com',
    projectId: 'pruebas-a312e',
    storageBucket: 'pruebas-a312e.appspot.com',
    messagingSenderId: '830401815913',
    appId: '1:830401815913:web:0c390a9f9fb3f91304c9f8',
    measurementId: 'G-1B3MT78F7E',
}
store.commit('app/INITIALIZE_FIREBASE', firebaseConfig)

firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        store.dispatch('user/setUser', user)
    } else {
        store.dispatch('user/setUser', null)
    }
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
